import React from "react";
import { galleryImage } from "../data/galleryImage";
import { CTA } from "./cta";
import { Image } from "./image";
export const Gallery = ({ title, description }) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{title}</h2>
          <p>
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec. */}
            {description}
          </p>
        </div>
        <div className="row" style={{ maxHeight: "80vh", overflow: "auto" }}>
          <div className="portfolio-items">
            {galleryImage.map((d, i) => (
              <div
                key={`${d.title}-${i}`}
                className="col-sm-6 col-md-4 col-lg-4"
                style={{
                  height: "250px", // Fixed height container
                  overflow: "hidden",
                }}
              >
                <Image
                  title={d.title}
                  largeImage={d.largeImage}
                  smallImage={d.smallImage}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", // Maintain aspect ratio and cover container
                    objectPosition: "center", // Center the image
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div style={{ marginTop: "24px" }}>
        <CTA />
      </div>
    </div>
  );
};
